import React from 'react'
import SEO from '~/components/SEO'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>PÁGINA NÃO ENCONTRADA</h1>
    <p>Essa rota não existe... volte para a página inicial</p>
  </>
)

export default NotFoundPage
